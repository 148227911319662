const RETRY = 0.5;
const MAX_ATTEMPTS = 60;
const BUTTON_ID = '#id-1c1af0fd';

function setInput(value) {
  const node = document.querySelector('input[data-label="signup_token"]');
  if (node && value) {
    node.value = value;
  }
  return node;
}

async function randomHexString() {
  const encoder = new TextEncoder();
  const randomBytes = crypto.getRandomValues(new Uint8Array(16));
  const textBytes = encoder.encode(navigator.userAgent);
  const dateBytes = encoder.encode(new Date().getTime());
  const final = new Uint8Array(randomBytes.length + textBytes.length + dateBytes.length);
  final.set(randomBytes, 0);
  final.set(textBytes, randomBytes.length);
  final.set(dateBytes, randomBytes.length + textBytes.length);

  return Array.from(new Uint8Array(await crypto.subtle.digest('SHA-256', final)))
    .map((b) => b.toString(16))
    .join('');
}

async function checkWebhookStatus(token, retries = 0) {
  if (retries > MAX_ATTEMPTS) {
    console.error('Max attempts reached, not found webhook status');
    return false;
  }

  const response = await fetch(`https://hub-api.jointangelo.com/users/signup_token/${token}`);
  const json = await response.json();

  console.log('Webhook status:', json);
  if (json.dietSurveyUrl) {
    document.querySelector(BUTTON_ID).onclick = () => window.location.href = appendQueryParameters(json.dietSurveyUrl, {
      redirectUri: `https://app.jointangelo.com/diet-survey/completed`,
      view: 'nosidebar,nohelp,nochat,noonboarding',
    });
  }

  if (json.error) {
    if (json.error === 'user not found' || json.error === 'user lacks diet survey url') {
      console.log('Expected missing info scenario, retrying...', json.error);

      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve();
        }, RETRY * 1000);
      }).then(() => checkWebhookStatus(token, retries + 1));
    } else {
      console.warn(json.error);
      return false;
    }
  }

  return false;
}

function appendQueryParameters(url, parameters){
  const parsedUrl = new URL(url);
  console.warn('parsedUrl', parsedUrl);

  const searchParams = new URLSearchParams(parsedUrl.search);
  console.warn('searchParams', searchParams.toString());

  Object.entries(parameters).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  parsedUrl.search = searchParams.toString();

  const final = parsedUrl.toString();
  console.warn('final', final);

  return final;
}


window.addEventListener('heyflow-submit', function (event) {
  console.log({ submitted: event });
  if (event && event.detail && event.detail.fieldsSimple && event.detail.fieldsSimple.signup_token) {
    checkWebhookStatus(event.detail.fieldsSimple.signup_token).catch(console.error).finally(console.log);/*.finally(() => {
      (heyflow['match-tangelo'] || heyflow['match-tangelo-clone']).navigation.navigate('next').catch(console.error);
    });*/
  }
});

window.addEventListener('heyflow-init', function () {
  randomHexString().then(input => setInput(input) && console.log(`Set input to ${input}`)).catch(console.error);
});

window.Client = {
  init: function () {

  },

  onRender: function (screenId) {

  }
};
